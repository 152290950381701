import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export default function Booking() {
  const { t } = useTranslation();

  return (
    <div className="container p-0 my-0 mt-3 pageClass px-3">
      <h2 className="mb-3">{t("booking_title")}</h2>
      {/* <div className="alert alert-warning alert-dismissible fade show rounded-0 bg-warning mt-3">
        {t("home_banner_1")}
      </div> */}
      <div className="pt-2">
        <p className="text-justify">
          {t("booking_courses")}{" "}
          <NavLink className="booking-courses" to="/courses">
            <b>{t("booking_courses2")}</b>
          </NavLink>
        </p>
      </div>

      <div className="pt-2">
        <p className="text-justify">
          {t("booking_faq1")}{" "}
          <NavLink className="booking-faq" to="/faq">
            <b>{t("booking_faq2")}</b>
          </NavLink>
        </p>
      </div>

      <div className="pt-2 mb-5">
        <p className="text-justify">
          <a
            className="  "
            href="https://bookeo.com/outoflines"
            target="_blank"
            rel="noreferrer"
          >
            {t("booking_statement1")}
          </a>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="currentColor"
            className="bi bi-arrow-up-right"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
            />
          </svg>{" "}
          {t("booking_statement2")}
        </p>

        <p className="fw-bold">{t("booking_statement3")}</p>
      </div>
    </div>
  );
}
